import React, { useState } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  const [isNavbarMobile, setIsNavbarMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState({});

  const handleNavToggle = () => {
    setIsNavbarMobile(!isNavbarMobile);
  };

  const closeMobileNav = () => {
    setIsNavbarMobile(false);
    setActiveDropdown({}); // Close all active dropdowns
  };

  const handleDropdownToggle = (e, index) => {
    e.preventDefault();
    if (isNavbarMobile) {
      setActiveDropdown((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/Home" onClick={closeMobileNav}>
              <img
                src="assets/img/Company.png"
                alt="Company Logo"
                className="img-fluid"
              />
            </Link>
          </div>

          <nav
            id="navbar"
            className={isNavbarMobile ? "navbar-mobile" : "navbar"}
          >
            <ul>
              <li>
                <Link
                  className="nav-link scrollto active"
                  to="/Home"
                  onClick={closeMobileNav}
                >
                  Home
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  to="/Services"
                  className="nav-link scrollto"
                  onClick={(e) => handleDropdownToggle(e, 1)}
                >
                  <span>Services</span> <i className="bi bi-chevron-down"></i>
                </Link>
                <ul className={activeDropdown[1] ? "dropdown-active" : ""}>
                  <li className="dropdown">
                    <Link to="#" onClick={(e) => handleDropdownToggle(e, 2)}>
                      <span>Mobile App Development</span>{" "}
                      <i className="bi bi-chevron-down"></i>
                    </Link>
                    <ul className={activeDropdown[2] ? "dropdown-active" : ""}>
                      <li>
                        <Link to="/Iosappdevelopment" onClick={closeMobileNav}>
                          iOS App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Androidappdevelopment"
                          onClick={closeMobileNav}
                        >
                          Android App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Hybridappdevelopment"
                          onClick={closeMobileNav}
                        >
                          Hybrid/Cross-Platform
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <Link to="#" onClick={(e) => handleDropdownToggle(e, 3)}>
                      <span>Web Development</span>{" "}
                      <i className="bi bi-chevron-down"></i>
                    </Link>
                    <ul className={activeDropdown[3] ? "dropdown-active" : ""}>
                      <li>
                        <Link to="/Webappdev" onClick={closeMobileNav}>
                          Web Application Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/Ecommercedev" onClick={closeMobileNav}>
                          eCommerce Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/Webandcmsdev" onClick={closeMobileNav}>
                          Web and CMS Development
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <Link to="#" onClick={(e) => handleDropdownToggle(e, 4)}>
                      <span>Software Development</span>{" "}
                      <i className="bi bi-chevron-down"></i>
                    </Link>
                    <ul className={activeDropdown[4] ? "dropdown-active" : ""}>
                      <li>
                        <Link
                          to="/Customersoftwaredev"
                          onClick={closeMobileNav}
                        >
                          Custom Software Development
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <Link
                  to="/HireDevelopers"
                  className="nav-link scrollto"
                  onClick={(e) => handleDropdownToggle(e, 5)}
                >
                  <span>Hire Developers</span>
                  <i className="bi bi-chevron-down"></i>
                </Link>
                <ul className={activeDropdown[5] ? "dropdown-active" : ""}>
                  <li>
                    <Link to="/Hirenodejsdev" onClick={closeMobileNav}>
                      Hire Node.js Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hirereactjsdev" onClick={closeMobileNav}>
                      Hire React.js Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hirecrossplatformdev" onClick={closeMobileNav}>
                      Hire Cross-Platform Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hirejavadev" onClick={closeMobileNav}>
                      Hire Java Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hireaspdotnetdev" onClick={closeMobileNav}>
                      Hire ASP.NET Core Developers
                    </Link>
                  </li>
                  <li>
                    <Link to="/Hireecommercedev" onClick={closeMobileNav}>
                      Hire eCommerce Developers
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className="nav-link scrollto"
                  to="/Portfolioin"
                  onClick={closeMobileNav}
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link scrollto"
                  to="/Contact"
                  onClick={closeMobileNav}
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  className="nav-link scrollto"
                  to="/Aboutus"
                  onClick={closeMobileNav}
                >
                  About Us
                </Link>
              </li>
            </ul>
            <i
              className={`bi ${
                isNavbarMobile ? "bi-x" : "bi-list"
              } mobile-nav-toggle`}
              onClick={handleNavToggle}
            ></i>
          </nav>
        </div>
      </header>
    </>
  );
}

export default Navbar;
