import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

class About extends React.Component {
    render() {
        return (
            <>
                <section id="about" className="about">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up">
                            <h2>About Us</h2>
                        </div>
                        <div className="row content">
                            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="150">
                                <p>
                                    Agm etech is a India-based software development company with offices in Ahmedabad, Gujarat India . Our highly talented and dedicated staff deliver work of exceptional quality, and can help you no matter what stage you are at in developing your software. We are totally committed to customer satisfaction, and this drives everything we do.
                                </p>
                                <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
                                    <p>
                                        <h5>WHO WE ARE</h5>
                                        AGM ETECH was started  as name Anantay IT Solutions in Ahmedabad-India in 2015. From 26th June 2021 We change name from Anantay IT Solutions to AGM Etech Private Limited. 
                                    </p>
                                </div>
                                <Link to="/Aboutus" className="btn-learn-more">Read More</Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section id="counts" className="counts">
                    <div className="container">

                        <div className="row">
                            <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-xl-start" data-aos="fade-right" data-aos-delay="150">
                                <img src="assets/img/counts-img.svg" alt="" className="img-fluid" />
                            </div>
                            <div className="col-xl-7 d-flex align-items-stretch pt-4 pt-xl-0" data-aos="fade-left" data-aos-delay="300">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i className="bi bi-emoji-smile"></i>
                                                <span data-purecounter-start="0" data-purecounter-end="65" data-purecounter-duration="1" className="purecounter"></span>
                                                <p><strong>Happy Clients</strong> consequuntur voluptas nostrum aliquid ipsam architecto ut.</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i className="bi bi-journal-richtext"></i>
                                                <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="1" className="purecounter"></span>
                                                <p><strong>Projects</strong> adipisci atque cum quia aspernatur totam laudantium et quia dere tan</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i className="bi bi-clock"></i>
                                                <span data-purecounter-start="0" data-purecounter-end="18" data-purecounter-duration="1" className="purecounter"></span>
                                                <p><strong>Years of experience</strong> aut commodi quaerat modi aliquam nam ducimus aut voluptate non vel</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6 d-md-flex align-items-md-stretch">
                                            <div className="count-box">
                                                <i className="bi bi-award"></i>
                                                <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" className="purecounter"></span>
                                                <p><strong>Awards</strong> rerum asperiores dolor alias quo reprehenderit eum et nemo pad der</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </>
        );
    }
}

export default About;