import React from "react";
import Carousel from "nuka-carousel";

class Portfolio extends React.Component {
  render() {
    return (
      <>
        <section className="Portfolios-odd">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2 className="text-light">Portfolio</h2>
            </div>

            <div className="row text-light">
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5 ">
                  <h4 className="text-light">SR Design & Construction</h4>
                  <div className="pdis">
                    <h6 className="text-light">Industry</h6>
                    <p>Planning, interiors Design and 3D modeling</p>
                  </div>

                  <div className="pdis">
                    <h6 className="text-light">Details</h6>
                    <p>
                      We are strong, sharp and experienced team of design,
                      construction, interiors & 3D modeling, ably supported by
                      our technical, administrative & back-end staff.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/DesktopPF-3.png"
                    alt="SR Design"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-even">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/Khaugali.png"
                    alt="Khaugali Restaurant Application"
                  />
                </figure>
              </div>
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4>Khaugali - Restaurant App</h4>
                  <div className="pdis">
                    <h6>Industry</h6>
                    <p>Restaurant</p>
                  </div>

                  <div className="pdis">
                    <h6>Details</h6>
                    <p>
                      Captain take order from mobile.Admin can manage by web
                      admin panel and desktop application.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-odd">
          <div className="container">
            <div className="row text-light">
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4 className="text-light">AD Publication</h4>
                  <div className="pdis">
                    <h6 className="text-light">Industry</h6>
                    <p>Publications</p>
                  </div>

                  <div className="pdis">
                    <h6 className="text-light">Details</h6>
                    <p>
                      Author Submit his/her paper, Reviewer and editor check
                      papers and give certificate.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/DesktopPF-4.png"
                    alt="AD Publication"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-even">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <figure>
                  <img src="assets/img/iPhoneSet.png" alt="Farm 2 Factory" />
                </figure>
              </div>
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4>Farm to Factory</h4>
                  <div className="pdis">
                    <h6>Industry</h6>
                    <p>
                      Tradding platform for Farmers,Pastrolist,Factory,Traders{" "}
                    </p>
                  </div>

                  <div className="pdis">
                    <h6>Details</h6>
                    <p>
                      Farmers,Pastrolist,Factory and Traders can post for buy
                      and sale products.Mobile application in three languages
                      Englsih, Hindi and Gujarati
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-odd">
          <div className="container">
            <div className="row text-light">
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4 className="text-light">Goverment Polytechnic College</h4>
                  <div className="pdis">
                    <h6 className="text-light">Industry</h6>
                    <p>Government College</p>
                  </div>

                  <div className="pdis">
                    <h6 className="text-light">Details</h6>
                    <p>
                      Website and Mobile Application for Staff members and
                      Students with many moudles like attendance,
                      Assignment,Timetable,Notiec, News, Events etc..
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/gpkheda.png"
                    alt="Government Polytechnic College"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-even">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/DesktopPF-1.png"
                    alt="Farm 2 Factory"
                  />
                </figure>
              </div>
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4>Micronics India</h4>
                  <div className="pdis">
                    <h6>Industry</h6>
                    <p>Data Recory Company </p>
                  </div>

                  <div className="pdis">
                    <h6>Details</h6>
                    <p>
                      India's number one data recovery company. Recover data
                      from Hard disk, Mobile, Server, CCTV/DVR.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-odd">
          <div className="container">
            <div className="row text-light">
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4 className="text-light">Anamay Diet Studio</h4>
                  <div className="pdis">
                    <h6 className="text-light">Industry</h6>
                    <p>Diet Studio - Health </p>
                  </div>

                  <div className="pdis">
                    <h6 className="text-light">Details</h6>
                    <p>
                      Anamay Diet Studio in Motera, Ahmedabad is a top player in
                      the category Dietitians in the Ahmedabad. This well-known
                      establishment acts as a one-stop destination servicing
                      customers both local and from other parts of Ahmedabad.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/Anamaydietstudio.png"
                    alt="Anamaya Diet Studio"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-even">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/cambayeducation.png"
                    alt="Cambay Institute of Hospitality Management"
                  />
                </figure>
              </div>
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4>Cambay Institute of Hospitality Management</h4>
                  <div className="pdis">
                    <h6>Industry</h6>
                    <p>Institute of Hospitality Management </p>
                  </div>

                  <div className="pdis">
                    <h6>Details</h6>
                    <p>
                      Cambay Institute of Hospitality Management (CIHM) is the
                      educational arm of Cambay Group of Hotels, Ahmedabad,
                      Gujarat. CIHM has started its journey since 2008 from
                      Gandhinagar (Gujrat) and expanded its operation to
                      Ahmedabad, Udaipur, Jaipur and Neemrana in Rajasthan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-odd">
          <div className="container">
            <div className="row text-light">
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4 className="text-light">Shantitech Solutions </h4>
                  <div className="pdis">
                    <h6 className="text-light">Industry</h6>
                    <p>Facility Service Managment Company </p>
                  </div>

                  <div className="pdis">
                    <h6 className="text-light">Details</h6>
                    <p>
                      Shantitech Solutions provie various services like House
                      keeping, Laundry Management, Pest Control, Engineering and
                      Maintanince services, Property Management, Landscaping and
                      Gardening and Manpower outsourcing service.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/shantitechsolutions.png"
                    alt="Shantitech Solutions"
                  />
                </figure>
              </div>
            </div>
          </div>{" "}
        </section>
        <section className="Portfolios-even">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8">
                <figure>
                  <img
                    src="assets/img/portfolio/OyeShakwalaMobile.png"
                    alt="Cambay Institute of Hospitality Management"
                  />
                </figure>
              </div>
              <div className="col-12 col-md-4">
                <div className="work-content pr-md-5">
                  <h4>Oye Shakwala</h4>
                  <div className="pdis">
                    <h6>Industry</h6>
                    <p>Online Platform for buy Fruits and Vegetables </p>
                  </div>

                  <div className="pdis">
                    <h6>Details</h6>
                    <p>
                      Oyeshakwal is online platform for buy vegetables and
                      fruits using mobile applications. Also integrated wallet
                      system for cashback and withdraw. One level marketing
                      system for customers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Portfolios-odd">
          <div className="container">
            <div className="row text-light">
              <div className="col-12 col-md-6">
                <div className="work-content pr-md-5">
                  <h4 className="text-light">POS - Urkupina </h4>
                  <div className="pdis">
                    <h6 className="text-light">Industry</h6>
                    <p>Bathroom Service Provider </p>
                  </div>

                  <div className="pdis">
                    <h6 className="text-light">Details</h6>
                    <p>
                      Urkupina is desktop POS system for provide use bathroom
                      service in Argentina.Usign this POS system ticket
                      generate, also card recharge and reedem with scanner.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <figure>
                  <img
                    src="assets/img/portfolio/Ukrania.png"
                    alt="Ukrania POS System"
                  />
                </figure>
              </div>
            </div>
          </div>{" "}
        </section>
        {/* <Carousel>
                            <div className="row">
                                <div className="col-12">
                                    <div className="work-carousel">
                                        <div className="owl-stage-outer">
                                            <div className="owl-stage">
                                                <div className="owl-item cloned">
                                                    <div className="item">
                                                        <div className="row">
                                                            <div className="col-12 col-md-6">
                                                                <div className="work-content pr-md-5">
                                                                    <h4>
                                                                        Micronics</h4>
                                                                    <div className="pdis">
                                                                        <h6>
                                                                            Industry</h6>
                                                                        <p>
                                                                            Data Recovery Service Provider</p>
                                                                    </div>
                                                                    <div className="pdis">
                                                                        <h6>
                                                                            Devices</h6>
                                                                        <p>
                                                                            HDD, Mobile, Server, Pendrive, Membory card</p>
                                                                    </div>
                                                                    <div className="pdis">
                                                                        <h6>
                                                                            Details</h6>
                                                                        <p>
                                                                            We Provide Service for Data recover from various devices. Server Recovery, Raid Recovery, Database Recovery, CCTV/DVR Recovery, Hard Disck Recovery</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6">
                                                                <figure><img src="assets/img/portfolio/DesktopPF-1.png" alt="Positive Results" /></figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                           
                        </Carousel> */}
        {/* </div>
                </section> */}
      </>
    );
  }
}
export default Portfolio;
