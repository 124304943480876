import React, { useState, useEffect } from "react";
import { db } from "../Config/firebase";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Contact = () => {
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
        const response = await fetch("https://myhrms.anantayitsolutions.com/WebInquiry/AddWebInquiryDetails", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: fname,
            lastName: lname,
            email: email,
            subject: subject,
            remarks: message,
          }),
        });
      
        if (response.ok) {
          alert("Contact information submitted successfully!");
          const data = await response.json();
          console.log(data);
        } else {
          alert("There was an error submitting the form.");
          console.error("Error:", response.statusText);
        }
      } catch (error) {
        alert("There was an error submitting the form.");
        console.error(error);
      }
      

    // db.collection("Contactus")
    //     .add({
    //         fname: fname,
    //         lname: lname,
    //         email: email,
    //         subject: subject,
    //         message: message,
    //     })
    //     .then(() => {
    //         setLoader(false);
    //         alert("Your from has been submitted");
    //     })
    //     .catch((error) => {
    //         alert(error.message);
    //         setLoader(false);
    //     });

    // Reset form fields
    setfname("");
    setlname("");
    setemail("");
    setsubject("");
    setmessage("");
  };
  return (
    <>
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contact us</h2>
          </div>
        </div>
      </section>

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div className="row gy-4"></div>
        </div>
      </section>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <form className="form" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="fname">First name</label>
                      <input
                        name="fname"
                        type="text"
                        className="form-control"
                        value={fname}
                        onChange={(e) => setfname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="lname">Last name</label>
                      <input
                        name="lname"
                        type="text"
                        className="form-control"
                        value={lname}
                        onChange={(e) => setlname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label for="email">Email</label>
                      <input
                        name="email"
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label for="subject">Subject</label>
                      <input
                        name="subject"
                        type="text"
                        className="form-control"
                        value={subject}
                        onChange={(e) => setsubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label for="message">Message</label>
                      <textarea
                        name="message"
                        className="form-control"
                        cols="30"
                        rows="10"
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-5 ml-auto">
              <h3 className="mb-3 side-title">Quick info</h3>
              <div className="quick-contact">
                <div className="d-flex">
                  <span className="icon-room"></span>
                  <address>
                    429 S.V. Square Building New Ranip, Ahmedabad, India
                  </address>
                </div>
                <div className="d-flex">
                  <span className="icon-phone"></span>
                  <Link to="#">+91 97123 12188</Link>
                </div>
                <div className="d-flex">
                  <span className="icon-envelope"></span>
                  <Link to="#">info@agmetech.com</Link>
                </div>
                <div className="d-flex">
                  <span className="icon-globe"></span>
                  <Link to="#">https://agmetech.com</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>
      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3670.2151855303!2d72.56275051444347!3d23.089217319629093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83c920483b19%3A0x403c61bbba9824f9!2sAGM%20Etech%20Private%20Limited!5e0!3m2!1sen!2sin!4v1676985044814!5m2!1sen!2sin"
                width="95%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <section id="portfolio-details" className="portfolio-details">
        <div className="container">
          <div className="row gy-4"></div>
        </div>
      </section>
    </>
  );
};

export default Contact;
